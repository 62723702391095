<template>
  <div id="login">
    <div id="bg">
      <div id="title">
        <img src="../assets/logoa.png" alt="" />
        <p>西南红火蚁普查监测防控大数据平台</p>
      </div>
      <div id="denglu">
        <el-form :model="form" status-icon :rules="rules" ref="form" label-width="100px" class="login-form">
          <p id="title-login">账号登录</p>
          <el-form-item id="zh" label="账号" prop="username">
            <el-input v-model.number="form.username"></el-input>
          </el-form-item>

          <el-form-item id="pwd" label="密码" prop="password">
            <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button id="btn" type="primary" @click="submitForm">登录</el-button>
          </el-form-item>
        </el-form>
        <div id="sure">
          <input type="checkbox" :checked="checked" @change="onChange" name="" id="" />
          <p>记住密码</p>
        </div>
        <!-- <div id="loging">
              <button>登录</button>
          </div> -->
      </div>
      <div id="logob">
        <p>
          <!-- <img src="../assets/logot.png" alt="" /> -->
          版权所有：成都世纪锐通科技有限公司
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { Login } from "../api/dataService";
export default {
  name: "login",
  data() {
    return {
      checked: true,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入昵称", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.checked = localStorage.getItem("loginRe") == "true";
    if (this.checked) {
      var name = localStorage.getItem("userName");
      var pwd = localStorage.getItem("userPwd");
      if (name) {
        this.form.username = name;
      }
      if (pwd) {
        this.form.password = pwd;
      }
    }
  },
  methods: {
    onChange(e) {
      this.checked = e.target.checked;
    },
    submitForm() {
      let param = {
        name: this.form.username,
        password: this.form.password,
      };
      Login(param).then((res) => {
        console.log(res);
        localStorage.setItem("Token", res.Data.token);
        localStorage.setItem("Lat", res.Data.Tenant.Lat);
        localStorage.setItem("Lng", res.Data.Tenant.Lng);
        localStorage.setItem("Address", res.Data.Tenant.Addr);
        localStorage.setItem("loginRe", this.checked);
        if (this.checked) {
          // localStorage.setItem("username", name);
          // localStorage.setItem("userpwd", password);

          localStorage.setItem("userName", this.form.username);
          localStorage.setItem("userPwd", this.form.password);
        }
        this.$router.push({ path: "/home" });
      });
    },
  },
};
</script>
<style>
@import "../assets/font/font.css";

#login {
  width: 100%;
  height: 100vh;
  background: url("../assets/big-bg.jpg") no-repeat center;
}

#bg {
  position: relative;
}

#title {
  position: absolute;
  top: 6rem;
  left: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#title img {
  width: 4rem;
  height: 4rem;
}

#title p {
  font-size: 3.5rem;
  color: white;
  font-family: "YouSheTiHei";
}

#denglu {
  width: 36rem;
  height: 41.6rem;
  background: url("../assets/denglu.png") no-repeat;
  position: absolute;
  top: 12rem;
  left: 40rem;
}

#title-login {
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
  position: absolute;
  top: 5rem;
  left: 13rem;
}

.el-form>div:nth-child(2)>label {
  position: absolute;
  top: 10rem;
  left: 4rem;
}

.el-form-item__label {
  color: white !important;
  font-size: 2rem !important;
  font-weight: bold !important;
}

.el-form-item__label:before {
  color: #ffffff !important;
}

.el-form>div:nth-child(3)>label {
  position: absolute;
  top: 20rem;
  left: 4rem;
}

.el-form>div:nth-child(2)>div,
.el-form>div:nth-child(3)>div {
  width: 25rem;
  height: 4rem;
}

.el-form>div:nth-child(2)>div {
  position: absolute;
  top: 14rem;
  left: -1rem;
}

.el-form>div:nth-child(3)>div {
  position: absolute;
  top: 24rem;
  left: -1rem;
}

#sure {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 12rem;
  left: 5.2rem;
}

#sure input {
  width: 1.2rem;
  height: 1.2rem;
}

#sure p {
  font-size: 1.2rem;
  color: #5cabfc;
  margin-left: 1rem;
}

#forget {
  position: absolute;
  bottom: 12rem;
  right: 5.8rem;
}

#forget p {
  font-size: 1.2rem;
  color: #ffffff;
}

#loging {
  position: absolute;
  bottom: 5rem;
  left: 10.5rem;
}

#btn {
  width: 20rem;
  height: 4rem;
  border: 1px solid transparent;
  border-radius: 2rem;
  background-color: #3c78f4;
  color: white;
  font-size: 2rem;
  position: absolute;
  top: 30rem;
  left: 1rem;
}

#logob p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}

#logob {
  position: absolute;
  top: 55.5rem;
  left: 45rem;
  z-index: 999;
}
</style>
