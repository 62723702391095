// 一些全局的config配置
const modeUrlObj = {
  // 生产环境
  production: {
    baseURL: "https://ant.scywk.cn/",
    authBaseURL: "",
  },
  // 开发环境
  development: {
    baseURL: "https://ant.scywk.cn/",
    authBaseURL: "",
  },
  // 测试环境
  test: {
    baseURL: "https://ant.scywk.cn/",
    authBaseURL: "",
  },
};
export default modeUrlObj[process.env.NODE_ENV];
