import Vue from "vue"; //引入Vue

import Router from "vue-router"; //引入vue-router

import Home from "../views/Home.vue"; //引入根目录下的Hello.vue组件
import Login from "../views/login.vue";
Vue.use(Router); //Vue全局使用Router

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    }, //配置路由，这里是个数组
    {
      path: "/home",
      name: "Home",
      component: Home,
    },
  ],
});
