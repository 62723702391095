<template>
  <div id="home">
    <div id="head">
      <div id="logo">
        <img src="../assets/logot.png" alt="" />
        <p>成都世纪锐通科技有限公司</p>
      </div>
      <div id="left-bg"></div>
      <div id="left-down"></div>
      <div id="head-name">
        <img src="../assets/head_name.png" alt="" />
      </div>
      <div id="light-l">
        <img src="../assets/light-l.png" alt="" />
      </div>
      <div id="light-r">
        <img src="../assets/light-r.png" alt="" />
      </div>
      <div id="right-bg"></div>
      <div id="right-down"></div>
      <div id="time">
        <p>{{ nowDate }}{{ nowWeek }}</p>
      </div>
      <div id="addr">
        <p>{{ personAdd }}</p>
      </div>
      <div id="out" @click="logout">
        <img src="../assets/out.png" alt="" />
      </div>
      <div id="r_black"></div>
      <div id="l_black"></div>
      <div id="b_black"></div>
    </div>
    <div id="map"></div>
    <div id="left-box">
      <div id="left-top">
        <p id="title-l">蚁情普查面积汇总</p>
        <div id="text">
          <vue-seamless-scroll :data="antlist" :class-option="classOption" class="warp">
            <ul class="item">
              <li v-for="(item, index) in antlist" :key="index">
                <div id="text-content">
                  <img src="../assets/msg.png" alt="" />
                  <div id="txt">
                    <p>{{ item.Addr }}出现蚁情</p>
                    <p>{{ item.Area }}亩</p>
                  </div>
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <div id="left-center">
        <p id="title-l">蚁情监测情况汇总</p>
        <div id="all">
          <div id="txt-title">
            <p>防治区域</p>
            <p>蚁巢数量</p>
            <p>发生面积</p>
            <p>工蚁数量</p>
          </div>
          <div id="content">
            <vue-seamless-scroll :data="jlist" :class-option="classOption" class="warps">
              <ul class="items">
                <li v-for="(item, index) in jlist" :key="index">
                  <div id="con">
                    <p>{{ item.Addr }}</p>
                    <p>{{ item.Amount }}</p>
                    <p>{{ item.Area }}</p>
                    <p>{{ item.AntNum }}</p>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div id="left-bottom">
        <p id="title-l">蚁情监测概况汇总</p>
        <div id="chartLineBox" style="width: 90%; height: 70%"></div>
      </div>
    </div>
    <div id="right-box">
      <div id="right-top">
        <p id="title-r">现场实施作业公告</p>
        <div class="block">
          <el-carousel trigger="hover" indicator-position="outside">
            <el-carousel-item v-for="(el, index) in project.titlePictureUrl" :key="index">
              <img height="88%" width="98%" style="margin-top: 3rem; margin-left: 0.5rem" :src="el" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div id="address">
          <p>{{ workAddr }}</p>
        </div>
      </div>
      <div id="right-center">
        <p id="title-r">蚁情任务进度汇总</p>
        <div id="yiBox1" ref="yiBox1"></div>
        <div id="yiBox2" ref="yiBox2"></div>
        <div id="yiBox3" ref="yiBox3"></div>
        <div id="lineBox" ref="lineBox"></div>
      </div>
      <div id="right-bottom">
        <p id="title-r">蚁情普查面积分析</p>
        <div id="zhuBox" ref="zhuBox"></div>
      </div>
    </div>
    <div id="bottom-box">
      <div id="bottom-left">
        <div id="bing" ref="bing"></div>
      </div>
      <div id="bottom-right">
        <div id="dengji" ref="dengji"></div>
        <div id="dlis">
          <div id="lis" v-for="(item, index) in dlist" :key="index">
            <div :style="{ backgroundColor: item.Color }"></div>
            <p>{{ item.GradeName }}</p>
          </div>
        </div>
        <div id="huanjing" ref="huanjing"></div>
        <div id="hlis">
          <div>
            <div></div>
            <p>果园</p>
          </div>
          <div>
            <div></div>
            <p>学校</p>
          </div>
          <div>
            <div></div>
            <p>小区</p>
          </div>
          <div>
            <div></div>
            <p>林地</p>
          </div>
          <div>
            <div></div>
            <p>农田</p>
          </div>
          <div>
            <div></div>
            <p>绿化带</p>
          </div>
          <div>
            <div></div>
            <p>荒地</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  type="text/javascript"
  src="https://mapapi.qq.com/web/jsapi/jsapi-gl/js/dom-to-image.min.js"
></script>
<script>
import * as echarts from "echarts";
import vueSeamlessScroll from "vue-seamless-scroll";
import {
  EntTypeCensusData,
  antMonitorData,
  censusAreaData,
  monitorData,
  InputsData,
  AntGradeData,
  taskScheduleData,
  EntTypeAntData,
  AntAreaData,
  list,
  workList,
  WorkDetail,
  sevenTask,
  antList,
  antData,
} from "../api/dataService";
export default {
  name: "Home",
  components: {
    vueSeamlessScroll,
  },
  data() {
    var imgUrl1 = require("../assets/001.png");
    var imgUrl2 = require("../assets/002.png");
    var imgUrl3 = require("../assets/001.png");
    return {
      msg: "index",
      project: {
        titlePictureUrl: [],
      },
      mark: 0, //比对图片索引的变量
      area: [],
      key: [],
      val: [],
      value: [],
      dchart: [],
      dcolor: [],
      dlist: [],
      complete: "",
      running: "",
      stay: "",
      all: "",
      timer: null,
      nowWeek: "",
      nowDate: "",
      hkey: [],
      hval: [],
      jlist: [],
      flist: [],
      skey: [],
      sval: [],
      wu: require("@/assets/wu.png"),
      qd: require("@/assets/qd.png"),
      yb: require("@/assets/yb.png"),
      zpz: require("@/assets/zpz.png"),
      zd: require("@/assets/zd.png"),
      yz: require("@/assets/yz.png"),
      mapList: {},
      map: {},
      tenMarker: {},
      tlist: [],
      workAddr: "",
      personAdd: "",
      hchart: [],
      antlist: [],
      infoWindow: {},
      classOption: {
        direction: 0,
        step: 0.5,
      },
    };
  },
  created() {
    // this.play();
    this.EntTypeCensusData();
    this.getAddr();
    this.antMonitorData();
    this.censusAreaData();
    this.monitorData();
    this.InputsData();
    this.AntGradeData();
    this.taskScheduleData();
    this.EntTypeAntData();
    this.AntAreaData();
    this.list();
    this.workList();
    this.sevenTask();
    this.antList();
    this.WorkDetail();
  },
  filters: {
    formatTimer: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m;
    },
  },
  methods: {
    //蚁情监测概况汇总线性图
    drawLine() {
      var _this = this;
      _this.chartLine = echarts.init(document.getElementById("chartLineBox"));
      // 基于准备好的dom，初始化echarts实例
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          //设置tip提示
          trigger: "axis",
        },

        legend: {
          //设置区分（哪条线属于什么）
          data: ["普查面积增长", "发生面积增长"],
          textStyle: {
            //图例文字属性设置
            color: "#ffffff",

            fontSize: "16",
          },
        },
        color: ["#3BC4E0", "#FD9F26"], //设置区分（每条线是什么颜色，和 legend 一一对应）
        xAxis: {
          //设置x轴
          type: "category",
          boundaryGap: false, //坐标轴两边不留白
          data: _this.key,
          axisLine: {
            //坐标轴轴线相关设置。
            lineStyle: {
              color: "#FFFFFF",
            },
          },
          axisTick: {
            show: false, // 取消x轴刻度
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "普查面积增长",
            data: this.value,
            type: "line", // 类型为折线图
            areaStyle: {
              normal: {
                color: "#43E2FF", //改变区域颜色
              },
            },
            lineStyle: {
              // 线条样式 => 必须使用normal属性
              normal: {
                color: "#3BC4E0",
              },
            },
          },
          {
            name: "发生面积增长",
            data: _this.val,
            type: "line",
            areaStyle: {
              normal: {
                color: "#FD9F26", //改变区域颜色
              },
            },
            lineStyle: {
              normal: {
                color: "#FD9F26",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      _this.chartLine.setOption(option);
    },

    //投入品饼图
    drawbing() {
      var _this = this;
      var names = [];

      this.tlist.forEach((item) => {
        names.push(item.name);
      });

      _this.mybChart = echarts.init(document.getElementById("bing"));
      // 基于准备好的dom，初始化echarts实例
      // 指定图表的配置项和数据
      var optionb = {
        //标题
        title: {
          text: "投入品消耗汇总",
          x: "center",
          y: "48", //标题位置
          textStyle: {
            //标题内容的样式
            color: "#fff",
            // fontStyle: 'normal',
            // fontWeight: 100,
            // fontSize: 16 //主题文字字体大小，默认为18px
          },
        },
        // stillShowZeroSum: true,
        //鼠标划过时饼状图上显示的数据
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c} ({d}%)",
        },
        //图例
        legend: {
          //图例  标注各种颜色代表的模块
          orient: "vertical", //图例的显示方式  默认横向显示
          bottom: 0, //控制图例出现的距离  默认左上角
          left: "200", //控制图例的位置
          // itemWidth: 16,//图例颜色块的宽度和高度
          // itemHeight: 12,
          textStyle: {
            //图例中文字的样式
            color: "#fff",
            fontSize: 14,
          },
          data:
            this.tlist.length <= 0
              ? ["诱蚁采集器", "杀蚁饵剂", "诱蚁饵剂", "杀蚁粉剂", "婚飞装置"]
              : names, //图例上显示的饼图各模块上的名字
        },
        //饼图中各模块的颜色
        // color: ["#1DC9EE", "#14E292", "#171BC6", "#FD9F26", "#F40505"],
        // 饼图数据
        series: {
          // name: 'bug分布',
          type: "pie", //echarts图的类型   pie代表饼图
          radius: ["30%", "50%"], //饼图中饼状部分的大小所占整个父元素的百分比
          center: ["30%", "70%"], //整个饼图在整个父元素中的位置
          // data:''               //饼图数据
          data:
            this.tlist <= 0
              ? [
                  //每个模块的名字和值
                  { name: "诱蚁采集器", value: 10 },
                  { name: "杀蚁饵剂", value: 20 },
                  { name: "诱蚁饵剂", value: 30 },
                  { name: "杀蚁粉剂", value: 30 },
                  { name: "婚飞装置", value: 30 },
                ]
              : this.tlist,
          itemStyle: {
            normal: {
              label: {
                show: false, //饼图上是否出现标注文字 标注各模块代表什么  默认是true
                // position: 'inner',//控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
              },
              labelLine: {
                show: false, //官网demo里外部标注上的小细线的显示隐藏    默认显示
              },
            },
          },
        },
      };
      this.mybChart.setOption(optionb);
    },
    //蚁情发生等级分析饼图
    dengji() {
      var _this = this;
      _this.mydChart = echarts.init(document.getElementById("dengji"));
      // 基于准备好的dom，初始化echarts实例
      // 指定图表的配置项和数据
      var optiond = {
        //标题
        title: {
          text: "蚁情发生等级分析",
          x: "center", //标题位置
          textStyle: {
            //标题内容的样式
            color: "#fff",
            // fontStyle: 'normal',
            // fontWeight: 100,
            // fontSize: 16 //主题文字字体大小，默认为18px
          },
          subtextStyle: {
            fontFamily: "微软雅黑",
            fontSize: 16,
            color: "#fff",
          },
        },
        // stillShowZeroSum: true,
        //鼠标划过时饼状图上显示的数据
        tooltip: {
          trigger: "item",
          formatter: "{a}<br/>{b}:{c}处 ({d}%)",
        },
        //图例

        //饼图中各模块的颜色
        color: this.dcolor,
        // 饼图数据
        series: {
          name: "蚁情发生等级分析",
          type: "pie", //echarts图的类型   pie代表饼图
          radius: ["40%", "50%"], //饼图中饼状部分的大小所占整个父元素的百分比
          center: ["50%", "50%"], //整个饼图在整个父元素中的位置
          // data:''               //饼图数据
          data: this.dchart,
          itemStyle: {
            normal: {
              label: {
                show: true, //饼图上是否出现标注文字 标注各模块代表什么  默认是true
                // position: 'inner',//控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
                position: "center",
              },
              labelLine: {
                show: false, //官网demo里外部标注上的小细线的显示隐藏    默认显示
              },
            },
          },
        },
      };
      this.mydChart.setOption(optiond);
    },
    //已完成仪表盘图
    yibiao1() {
      var _this = this;
      _this.myChart = echarts.init(document.getElementById("yiBox1"));
      var options = {
        //提示框组件。开发实际中去掉了指针，提示框可以不用设置。
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        //工具栏。本次好像也没用到，刷新，下载，我觉得还是挺实用的，具体开需求！
        // toolbox: {
        //   feature: {
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        //下面属性才是仪表盘的核心！！反正我是这么认为的！！！
        series: [
          {
            //类型
            type: "gauge",
            //半径
            radius: 48,
            //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
            startAngle: 180,
            //结束角度。
            endAngle: 0,
            center: ["50%", "30%"],
            //仪表盘轴线相关配置。
            axisLine: {
              show: true,
              color: "#333",
              // 属性lineStyle控制线条样式
              lineStyle: {
                width: 10,
                color: [
                  [
                    this.complete / this.all,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#2EC6FF",
                      },
                      {
                        offset: 1,
                        color: "#36FF90",
                      },
                    ]),
                  ],
                  [1, "#113142"],
                ],
              },
            },
            //分隔线样式。
            splitLine: {
              show: false,
            },
            //刻度样式。
            axisTick: {
              show: false,
            },
            //刻度标签。
            axisLabel: {
              show: false,
            },
            //仪表盘指针。
            pointer: {
              //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
              show: false,
              //指针长度
              length: "90%",
              width: 0,
            },
            //仪表盘标题。
            title: {
              show: false,
              offsetCenter: [0, "-40%"], // x, y，单位px
              textStyle: {
                color: "#000",
                fontSize: 10,
              },
            },
            //仪表盘详情，用于显示数据。
            detail: {
              show: true,
              offsetCenter: [0, "-10%"],
              formatter: "{value}%\n已完成",

              textStyle: {
                fontSize: 14,
                color: "#36FF90",
              },
            },
            data: [
              {
                value: (this.complete / this.all) * 100,
                name: "存储量",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(options);
    },
    //进行中仪表盘图
    yibiao2() {
      var _this = this;
      _this.myChart = echarts.init(document.getElementById("yiBox2"));
      var options = {
        //提示框组件。开发实际中去掉了指针，提示框可以不用设置。
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        //工具栏。本次好像也没用到，刷新，下载，我觉得还是挺实用的，具体开需求！
        // toolbox: {
        //   feature: {
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        //下面属性才是仪表盘的核心！！反正我是这么认为的！！！
        series: [
          {
            //类型
            type: "gauge",
            //半径
            radius: 48,
            //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
            startAngle: 180,
            //结束角度。
            endAngle: 0,
            center: ["50%", "30%"],
            //仪表盘轴线相关配置。
            axisLine: {
              show: true,
              color: "#333",
              // 属性lineStyle控制线条样式
              lineStyle: {
                width: 10,
                color: [
                  [
                    this.running / this.all,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#2EC6FF",
                      },
                      {
                        offset: 1,
                        color: "#3686FF",
                      },
                    ]),
                  ],
                  [1, "#113142"],
                ],
              },
            },
            //分隔线样式。
            splitLine: {
              show: false,
            },
            //刻度样式。
            axisTick: {
              show: false,
            },
            //刻度标签。
            axisLabel: {
              show: false,
            },
            //仪表盘指针。
            pointer: {
              //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
              show: false,
              //指针长度
              length: "90%",
              width: 0,
            },
            //仪表盘标题。
            title: {
              show: false,
              offsetCenter: [0, "-40%"], // x, y，单位px
              textStyle: {
                color: "#000",
                fontSize: 10,
              },
            },
            //仪表盘详情，用于显示数据。
            detail: {
              show: true,
              offsetCenter: [0, "-10%"],
              formatter: "{value}%\n进行中",

              textStyle: {
                fontSize: 14,
                color: "#2EC6FF",
              },
            },
            data: [
              {
                value: (this.running / this.all) * 100,
                name: "存储量",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(options);
    },
    //待处理仪表盘图
    yibiao3() {
      var _this = this;
      _this.myChart = echarts.init(document.getElementById("yiBox3"));
      var options = {
        //提示框组件。开发实际中去掉了指针，提示框可以不用设置。
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%",
        },
        //工具栏。本次好像也没用到，刷新，下载，我觉得还是挺实用的，具体开需求！
        // toolbox: {
        //   feature: {
        //     restore: {},
        //     saveAsImage: {}
        //   }
        // },
        //下面属性才是仪表盘的核心！！反正我是这么认为的！！！
        series: [
          {
            //类型
            type: "gauge",
            //半径
            radius: 48,
            //起始角度。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
            startAngle: 180,
            //结束角度。
            endAngle: 0,
            center: ["50%", "30%"],
            //仪表盘轴线相关配置。
            axisLine: {
              show: true,
              color: "#333",
              // 属性lineStyle控制线条样式
              lineStyle: {
                width: 10,
                color: [
                  [
                    this.stay / this.all,
                    new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0.1,
                        color: "#FF2ECB",
                      },
                      {
                        offset: 1,
                        color: "#FF3644",
                      },
                    ]),
                  ],
                  [1, "#113142"],
                ],
              },
            },
            //分隔线样式。
            splitLine: {
              show: false,
            },
            //刻度样式。
            axisTick: {
              show: false,
            },
            //刻度标签。
            axisLabel: {
              show: false,
            },
            //仪表盘指针。
            pointer: {
              //这个show属性好像有问题，因为在这次开发中，需要去掉指正，我设置false的时候，还是显示指针，估计是BUG吧，我用的echarts-3.2.3；希望改进。最终，我把width属性设置为0，成功搞定！
              show: false,
              //指针长度
              length: "90%",
              width: 0,
            },
            //仪表盘标题。
            title: {
              show: false,
              offsetCenter: [0, "-40%"], // x, y，单位px
              textStyle: {
                color: "#000",
                fontSize: 10,
              },
            },
            //仪表盘详情，用于显示数据。
            detail: {
              show: true,
              offsetCenter: [0, "-10%"],
              formatter: "{value}%\n待处理",

              textStyle: {
                fontSize: 14,
                color: "#FF3644",
              },
            },
            data: [
              {
                value: (this.stay / this.all) * 100,
                name: "存储量",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(options);
    },
    //蚁情普查面积分析柱状图
    zhuBox() {
      var _this = this;
      _this.myCharts = echarts.init(document.getElementById("zhuBox"));
      var optionz = {
        title: {
          text: "",
          x: "center",
          y: "top",
          textStyle: {
            fontSize: 18,
            color: "#fff",
          },
        },
        tooltip: {},
        xAxis: {
          data: this.hkey,
          axisTick: {
            show: false, // 取消x轴刻度
          },
          axisLabel: {
            textStyle: {
              show: true,
              color: "#fff",
              fontSize: "12",
            },
          },
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              show: true,
              color: "#fff",
              fontSize: "10",
            },
          },
        },
        series: [
          {
            name: "蚁情普查面积分析",
            type: "bar",
            data: this.hval,
            barMaxWidth: 30,
          },
        ],
        color: ["#66FF99"],
      };
      _this.myCharts.setOption(optionz);
    },
    //近7天任务完成数量统计图
    lineBox() {
      var _this = this;
      _this.mylChart = echarts.init(document.getElementById("lineBox"));
      var optionl = {
        // title: {
        //   text: "",
        //   x: "12",
        //   y:"35", //标题位置
        //   textStyle: {
        //     //标题内容的样式
        //     color: "#fff",
        //     // fontStyle: 'normal',
        //     // fontWeight: 100,
        //     fontSize: 12 //主题文字字体大小，默认为18px
        //   },
        // },
        tooltip: {
          //设置tip提示
          trigger: "axis",
        },
        color: ["#3BC4E0"], //设置区分（每条线是什么颜色，和 legend 一一对应）
        xAxis: {
          //设置x轴
          type: "category",
          boundaryGap: false, //坐标轴两边不留白
          data: _this.skey,
          axisLine: {
            //坐标轴轴线相关设置。
            lineStyle: {
              color: "#FFFFFF",
            },
          },
          axisLabel: {
            interval: 0,
            rotate: 50,
          },
          axisTick: {
            show: false, // 取消x轴刻度
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#FFFFFF",
            },
          },
          type: "value",
        },
        series: [
          {
            name: "近7天任务完成数量统计",
            data: _this.sval,
            type: "line", // 类型为折线图
            areaStyle: {
              normal: {
                color: "#43E2FF", //改变区域颜色
              },
            },
            lineStyle: {
              // 线条样式 => 必须使用normal属性
              normal: {
                color: "#3BC4E0",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      this.mylChart.setOption(optionl);
    },
    //蚁情发生面积分析饼图
    EntType() {
      var _this = this;
      _this.myhChart = echarts.init(document.getElementById("huanjing"));
      var optionh = {
        //标题
        title: {
          text: "蚁情发生面积分析",
          x: "center", //标题位置
          textStyle: {
            //标题内容的样式
            color: "#fff",
            // fontStyle: 'normal',
            // fontWeight: 100,
            // fontSize: 16 //主题文字字体大小，默认为18px
          },
        },
        // stillShowZeroSum: true,
        //鼠标划过时饼状图上显示的数据
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c} ({d}%)",
        },
        //图例

        //饼图中各模块的颜色
        color: [
          "#0884E3",
          "#FFE60F",
          "#FFB30F",
          "#15FF0F",
          "#21B604",
          "#0FE0FF",
          "#E30822",
        ],
        // 饼图数据
        series: {
          // name: 'bug分布',
          type: "pie", //echarts图的类型   pie代表饼图
          radius: "50%", //饼图中饼状部分的大小所占整个父元素的百分比
          center: ["50%", "50%"], //整个饼图在整个父元素中的位置
          // data:''               //饼图数据
          data: _this.hchart,
          itemStyle: {
            normal: {
              label: {
                show: true, //饼图上是否出现标注文字 标注各模块代表什么  默认是true
                position: "center", //控制饼图上标注文字相对于饼图的位置  默认位置在饼图外
              },
              labelLine: {
                show: false, //官网demo里外部标注上的小细线的显示隐藏    默认显示
              },
            },
          },
        },
      };
      this.myhChart.setOption(optionh);
    },
    //初始化地图
    async initMap() {
      // 定义地图中心点坐标
      if (this.map.height) {
        this.map.destroy();
      }
      let TMap = window.TMap;
      var center = new window.TMap.LatLng(
        localStorage.getItem("Lat"),
        localStorage.getItem("Lng")
      );
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      this.map = new window.TMap.Map(document.getElementById("map"), {
        center: center,
        zoom: 12, // 设置地图缩放级别
        minZoom: 6,
        maxZomm: 16,
        renderOptions: {
          enableBloom: true, //是否启用泛光效果 注：为true才会有效果
          preserveDrawingBuffer: true, // 保留地图的渲染缓冲 默认false
        },
        baseMap: {
          // 设置卫星地图
          type: "satellite",
        },
      });

      this.tenMarker = new TMap.MultiMarker({
        id: "marker-layer", //图层id
        position: center,
        map: this.map,
        //   // 样式定义
        styles: {
          // 创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.wu,
          }),
          无: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.wu,
          }),
          轻度: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.qd,
          }),
          中度: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.zd,
          }),
          中偏重: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.zpz,
          }),
          重度: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.zd,
          }),
          严重: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            anchor: { x: 16, y: 32 },
            src: this.yz,
          }),
        },
        //   // 点标记数据数组
        geometries: [],
      });
      this.infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(39.984104, 116.307503),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素
      });
    },
    //地图标点及热力图
    mapMake() {
      //热力图
      var heat = new TMap.visualization.Heat({
        max: 350, // 热力最强阈值
        min: 0, // 热力最弱阈值nitMap
        height: 0, // 峰值高度
        radius: 30, // 最大辐射半径
      }).addTo(this.map);
      var headPoints = [];
      this.mapList.forEach((item) => {
        if (item.Area > 0) {
          headPoints.push({
            lng: parseFloat(item.Lng),
            lat: parseFloat(item.Lat),
            count: item.Area,
          });
        }
      });
      heat.setData(headPoints);
      this.tenMarker.addListener("click", this.markerClick);
      //地图标点

      //清空旧的标注点
      this.tenMarker.remove(this.markerIds);
      //计算新的标注点
      var points = [];
      var markerIds = [];
      var userList = [];
      this.mapList.forEach((item) => {
        points.push({
          id: item.Id,
          styleId: item.GradeName,
          position: new TMap.LatLng(item.Lat, item.Lng), // 将得到的坐标位置用点标记标注在地图上
        });
        if (userList.indexOf(item.UserName) == -1) {
          userList.push(item.UserName);
        }
      });
      this.userList = userList;
      this.markerIds = markerIds;
      this.tenMarker.updateGeometries(points);
    },
    // 信息窗口内容
    markerClick(e) {
      var id = e.geometry.id;
      let param = {
        Id: id,
      };

      if (this.infoWindow) {
        this.infoWindow.close();
      }
      antData(param).then((res) => {
        // console.log(res);
        this.infoWindow = new TMap.InfoWindow({
          map: this.map,
          position: new TMap.LatLng(res.Data.Lat, res.Data.Lng),
          offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素
        });
        //初始关闭信息窗关闭
        //监听标注点击事件
        // this.tenMarker.addListener("click", this.markerClick);
        this.infoWindow.open(); //打开信息窗
        this.infoWindow.setPosition(this.infoWindow.position); //设置信息窗位置
        this.infoWindow.setContent(
          "<div id='tan' style='justify-content: flex-start;align-items: center;flex-wrap: wrap;padding:5px 10px;'><p>" +
            res.Data.Addr +
            "</p><br/><p style='color:red'>蚁情等级：" +
            res.Data.GradeName +
            "</p><br/><p>操作人员：" +
            res.Data.UserName +
            "</p><br/><p>蚁巢数量(个)：" +
            res.Data.Amount +
            "</p><br/><p>工蚁数量(个)：" +
            res.Data.AntNum +
            "</p><br/><p>发生面积(亩)：" +
            res.Data.Area +
            "</p></div>"
        );
      });
    },
    //环境类型普查数据
    EntTypeCensusData() {
      let param = {};
      EntTypeCensusData(param).then((res) => {
        for (var i = 0; i < res.Data.length; i++) {
          this.hkey.push(res.Data[i].Key);
          this.hval.push(res.Data[i].Val);
        }

        this.zhuBox();
      });
    },
    // 获得账号的地址
    getAddr() {
      this.personAdd = localStorage.getItem("Address");
    },
    //地图标点
    antMonitorData() {
      let param = {};
      antMonitorData(param).then((res) => {
        this.mapList = res.Data;
        this.mapMake();
      });
    },
    //普查面积
    censusAreaData() {
      let param = {
        Obj: 5,
      };
      censusAreaData(param).then((res) => {
        this.area = res.Data;
      });
    },
    //监测面积
    monitorData() {
      let param = {
        Obj: 7,
      };
      monitorData(param).then((res) => {});
    },
    //投入器数据
    async InputsData() {
      let param = {};
      this.tlist = [];
      await InputsData(param).then((res) => {
        for (var i = 0; i < res.Data.length; i++) {
          this.tlist.push({
            name: res.Data[i].Key,
            value: res.Data[i].Val,
          });
        }
        this.drawbing();
      });
    },
    //等级统计数据
    AntGradeData() {
      let param = {};
      AntGradeData(param).then((res) => {
        this.dlist = res.Data;
        for (var i = 0; i < res.Data.length; i++) {
          this.dchart.push({
            name: res.Data[i].GradeName,
            value: res.Data[i].Num,
          });
          this.dcolor.push(res.Data[i].Color);
        }
        this.dengji();
      });
    },
    //任务统计数据
    taskScheduleData() {
      let param = {};
      taskScheduleData(param).then((res) => {
        this.complete = res.Data.Complete;
        this.running = res.Data.Running;
        this.stay = res.Data.Stay;
        this.all =
          res.Data.Complete + res.Data.Running + res.Data.Stay == 0
            ? 1
            : res.Data.Complete + res.Data.Running + res.Data.Stay;
        this.yibiao1();
        this.yibiao2();
        this.yibiao3();
      });
    },
    //环境类型数据
    EntTypeAntData() {
      let param = {};
      EntTypeAntData(param).then((res) => {
        for (var i = 0; i < res.Data.length; i++) {
          this.hchart.push({
            name: res.Data[i].Key,
            value: res.Data[i].Val,
          });
        }
        this.EntType();
      });
    },
    //蚁情面积数据
    AntAreaData() {
      let param = {
        Obj: 7,
      };
      AntAreaData(param).then((res) => {
        for (var i = 0; i < res.Data.length; i++) {
          this.key.push(res.Data[i].Key);
          this.val.push(res.Data[i].Val.AntArea);
          this.value.push(res.Data[i].Val.MonitorArea);
        }
        this.drawLine();
      });
    },
    //蚁情监测数据
    list() {
      let param = {
        page: 1,
        pageSize: 20,
      };
      list(param).then((res) => {
        this.jlist = res.Data.DataList;
      });
    },
    //
    workList() {
      let param = {
        IsAll: true,
        Filter: null,
      };
      workList(param).then((res) => {
        this.flist = res.Data.DataList;
      });
    },
    //作业实施明细
    WorkDetail() {
      let param = {};
      WorkDetail(param).then((res) => {
        this.workAddr = res.Data[0].Addr;
        res.Data.forEach((item) => {
          item.Imgs.forEach((item) => {
            this.project.titlePictureUrl.push(item.FilePath + item.FileName);
          });
        });
      });
    },
    //获取当前时间
    setNowTimes() {
      let myDate = new Date();
      let wk = myDate.getDay();
      let yy = String(myDate.getFullYear());
      let mm = myDate.getMonth() + 1;
      let dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      this.nowDate = yy + "/" + mm + "/" + dd;
      this.nowWeek = week;
    },
    //7天任务完成数据
    sevenTask() {
      let param = {};
      sevenTask(param).then((res) => {
        for (var i = 0; i < res.Data.CompleteData.length; i++) {
          this.skey.push(res.Data.CompleteData[i].Key);
          this.sval.push(res.Data.CompleteData[i].Val);
        }
        this.lineBox();
      });
    },
    antList() {
      let param = {};
      antList(param).then((res) => {
        this.antlist = res.Data.DataList;
      });
    },
    logout() {
      window.sessionStorage.clear();
      // 跳转到登录页
      this.$router.push("/");
    },
    //   跳转到登录页
  },
  mounted() {
    var _this = this;
    this.timer = setInterval(function () {
      _this.setNowTimes(); //修改数据date
    }, 1000);
    _this.initMap();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //在vue实例销毁钱，清除我们的定时器
    }
  },
};
</script>
<style>
@import "../assets/font/font.css";

#home {
  width: 100%;
  background: url("../assets/all.png") no-repeat;
  position: relative;
}

#map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#head {
  width: 100%;
  height: 108px;
  background: url("../assets/bg_head2.png") no-repeat center;
  position: relative;
  z-index: 999;
}

#left-bg {
  width: 13rem;
  height: 2rem;
  position: absolute;
  top: 3.1rem;
  left: 22.5rem;
  background: url("../assets/left_bg.png") no-repeat;
  z-index: 999;
}

#left-down {
  width: 39rem;
  height: 4rem;
  position: absolute;
  top: 4.7rem;
  left: 0rem;
  background: url("../assets/left_down.png") no-repeat;
  z-index: 999;
}

#right-bg {
  width: 13rem;
  height: 2rem;
  position: absolute;
  top: 3.1rem;
  right: 22.5rem;
  background: url("../assets/right_bg.png") no-repeat;
  z-index: 999;
}

#right-down {
  width: 39rem;
  height: 4rem;
  position: absolute;
  top: 4.7rem;
  right: 0rem;
  background: url("../assets/right_down.png") no-repeat;
  z-index: 999;
}

#head-name {
  width: 40rem;
  height: 3rem;
  position: absolute;
  top: 1.4rem;
  left: 40rem;
  z-index: 999;
}

#head-name img {
  width: 100%;
  height: 100%;
}

#logo {
  width: 20rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0.7rem;
  left: 5rem;
}

#logo p {
  color: white;
  font-family: "YouSheTiHei";
  font-size: 1.23rem;
  margin-left: 0.3rem;
}

#time {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 1rem;
  right: 15.5rem;
  z-index: 999;
}

#time p {
  color: white;
  font-family: "YouSheTiHei";
  font-size: 1.23rem;
}

#addr {
  width: 12rem;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 1rem;
  right: 2rem;
  z-index: 999;
}

#addr p {
  color: white;
  font-family: "YouSheTiHei";
  font-size: 1.23rem;
}

#left-box {
  width: 32rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

#left-top,
#left-center,
#left-bottom {
  width: 90%;
  height: 19rem;
  background: url("../assets/left.png") no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 999;
}

#left-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

#title-l {
  position: absolute;
  top: 1rem;
  left: 3rem;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

#text {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: absolute;
  top: 4rem;
  left: 1.5rem;
  overflow: hidden;
}

.warp {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.warp ul {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.warp li {
  width: 100%;
  display: block;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}

#text-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

#txt {
  background: url("../assets/kuang.png") no-repeat;
  width: 92%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.7rem;
  margin-left: 0.5rem;
  padding-left: 0.2rem;
}

#txt p {
  color: white;
  font-size: 0.8rem;
}

#txt p:nth-child(1) {
  width: 20rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#txt p:nth-child(2) {
  width: 4rem;
}

#all {
  width: 90%;
  height: 70%;
  position: absolute;
  top: 4rem;
  left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

#txt-title {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #0ca1f1;
  padding-bottom: 0.1rem;
}

#txt-title p {
  font-size: 0.8rem;
  color: #3198eb;
  margin-left: 3rem;
}

#txt-title p:nth-child(1) {
  margin-right: 4rem;
}

#txt-title p:nth-child(2),
#txt-title p:nth-child(3),
#txt-title p:nth-child(4) {
  margin-left: 2rem;
}

#content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

.warps {
  width: 100%;
}

#con {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.1rem;
  border-bottom: 1px solid #0ca1f1;
  padding-bottom: 0.1rem;
  padding-left: 0.2rem;
}

#con p {
  font-size: 0.9rem;
  color: #5cccfa;
}

#con p:nth-child(1) {
  width: 45%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#con p:nth-child(2) {
  width: 13%;
  text-align: center;
  margin-right: 2rem;
}

#con p:nth-child(3) {
  width: 13%;
  text-align: center;
  margin-right: 2rem;
}

#con p:nth-child(4) {
  width: 13%;
  text-align: center;
}

#title-r {
  position: absolute;
  top: 1rem;
  right: 3rem;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

#chartLineBox {
  position: absolute;
  top: 5.6rem;
}

#box {
  display: flex;
  justify-content: space-between;
  align-content: center;
  position: relative;
}

#left-box {
  width: 32rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

#left-top,
#left-center,
#left-bottom {
  width: 90%;
  height: 19rem;
  background: url("../assets/left.png") no-repeat;
  position: relative;
  overflow: hidden;
  z-index: 999;
}

#right-box {
  position: absolute;
  top: 6.7rem;
  right: 0.2rem;
  width: 32rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

#right-top,
#right-center,
#right-bottom {
  width: 90%;
  height: 19rem;
  background: url("../assets/right.png") no-repeat;
  position: relative;
  z-index: 999;
}

#right-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#bottom-box {
  width: 60rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap-reverse;
  position: absolute;
  bottom: 0rem;
  left: 29.65rem;
  z-index: 999;
}

#bottom-left {
  width: 36%;
  height: 19rem;
  background: url("../assets/bottom-left.png") no-repeat;
  position: relative;
}

#bottom-left p {
  position: absolute;
  top: 1.5rem;
  left: 7rem;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
}

#bottom-right {
  width: 60%;
  height: 19rem;
  background: url("../assets/bottom-right.png") no-repeat;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#light-l {
  position: absolute;
  top: -0.3rem;
  left: 38rem;
}

#light-r {
  position: absolute;
  top: 1.9rem;
  right: 38.3rem;
}

.banner {
  width: 95%;
  margin: 0 auto;
  position: relative;
  margin-top: 3rem;
}

.slide {
  width: 100%;
  height: 15rem;
  margin: 0 auto;
  /* margin-top: 50px; */
  overflow: hidden;
  position: relative;
}

.slideshow {
  width: 100%;
  height: 100%;
}

.bar {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
  z-index: 999;
  text-align: center;
}

.bar span {
  width: 14px;
  height: 14px;
  background: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 20px;
}

.active {
  background: #a63300 !important;
}

#yiBox1 {
  width: 33%;
  height: 70%;
}

#yiBox2 {
  width: 33%;
  height: 70%;
}

#yiBox3 {
  width: 33%;
  height: 70%;
}

#zhuBox {
  width: 100%;
  height: 80%;
  margin-top: 3.8rem;
}

#bing {
  width: 100%;
  height: 70%;
  position: absolute;
  top: 1rem;
}

#dengji {
  width: 50%;
  height: 70%;
  position: absolute;
  top: 4rem;
}

#huanjing {
  width: 50%;
  height: 70%;
  position: absolute;
  top: 4rem;
  left: 16rem;
}

#dlis {
  width: 5rem;
  position: absolute;
  top: 6rem;
  left: 14rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

#lis {
  width: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

#lis div {
  width: 1.5rem;
  height: 1rem;
  margin-top: 0.3rem;
  border-radius: 5px;
}

#lis div:nth-child(1) {
  background-color: #44d748;
}

#lis div:nth-child(3) {
  background-color: #ffe60f;
}

#lis div:nth-child(5) {
  background-color: #fcb335;
}

#lis div:nth-child(7) {
  background-color: #ff6000;
}

#lis div:nth-child(9) {
  background-color: #fb363b;
}

#lis p {
  width: 60%;
  color: white;
  font-size: 1rem;
  margin-left: 0.3rem;
  margin-top: 0.3rem;
}

#lineBox {
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0rem;
  left: 0;
}

.el-carousel__item {
  height: 90% !important;
  width: 95% !important;
  position: absolute !important;
  top: 5px !important;
  left: 8px !important;
}

.block {
  width: 92% !important;
  margin-left: 1rem !important;
}

#address {
  position: absolute;
  top: 15rem;
  left: 2rem;
  z-index: 999;
}

#address p {
  width: 13rem;
  color: white;
  font-size: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#hlis {
  width: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: absolute;
  left: 30rem;
  top: 5rem;
}

#hlis>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

#hlis>div>div {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

#hlis>div:nth-child(1)>div {
  background-color: #0884e3;
}

#hlis>div:nth-child(2)>div {
  background-color: #ffe60f;
}

#hlis>div:nth-child(3)>div {
  background-color: #ffb30f;
}

#hlis>div:nth-child(4)>div {
  background-color: #15ff0f;
}

#hlis>div:nth-child(5)>div {
  background-color: #21b604;
}

#hlis>div:nth-child(6)>div {
  background-color: #0fe0ff;
}

#hlis>div:nth-child(7)>div {
  background-color: #e30822;
}

#hlis p {
  color: #ffffff;
  font-size: 0.8rem;
}

#map>div>div:nth-child(2)>div {
  display: none !important;
}

#out {
  position: absolute;
  top: 1.2rem;
  right: 0.5rem;
}

#out img {
  width: 1.5rem;
  height: 1.5rem;
}

#b_black {
  width: 100%;
  height: 7rem;
  position: absolute;
  bottom: -57rem;
  left: 0rem;
  background: url("../assets/b_black.png") no-repeat;
  z-index: 1;
}

#r_black {
  width: 7rem;
  height: 58rem;
  position: absolute;
  top: 5rem;
  right: 0rem;
  background: url("../assets/r_black.png") no-repeat;
  z-index: 1;
}

#l_black {
  width: 7rem;
  height: 58rem;
  position: absolute;
  top: 5rem;
  left: 0rem;
  background: url("../assets/l_black.png") no-repeat;
  z-index: 1;
}
</style>
