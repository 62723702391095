<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import {EntTypeCensusData} from "../src/api/dataService";
export default {
  name: "App",
};
</script>

<style></style>
