import http from '../utils/http';
import modeUrlObj from '../../public';

export function EntTypeCensusData(param) {
  // 普查面积按环境类型统计
  let url = `/api/BigData/EntTypeCensusData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function Login(param) {
  // 登陆
  let url = `/api/antAdmin/Authorize/Login`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function EntTypeAntData(param) {
  // 蚁情发生面积按环境类型统计
  let url = `/api/BigData/EntTypeAntData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function AntGradeData(param) {
  // 蚁情发生等级统计
  let url = `/api/BigData/AntGradeData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function WorkDetail(param) {
  // 蚁实施明细数据
  let url = `/api/BigData/WorkDetail`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function InputsData(param) {
  // 投入品消耗数据
  let url = `/api/BigData/InputsData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function AntAreaData(param) {
  // 蚁情面积数据统计
  let url = `/api/BigData/AntAreaData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function censusAreaData(param) {
  // 按天统计普查面积
  let url = `/api/AntData/censusAreaData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function monitorData(param) {
  // 按天统计监测数数
  let url = `/api/AntData/monitorData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function taskScheduleData(param) {
  // 防控作业进度统计
  let url = `/api/AntData/taskScheduleData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function antMonitorData(param) {
  // 获取蚁情监测分布数据
  let url = `/api/AntData/antMonitorData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function list(param) {
  // 获取蚁情监测数据列表
  let url = `/api/antAdmin/AntData/list`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function workList(param) {
  // 获取防控作业列表
  let url = `/api/antAdmin/AntTask/workList`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function sevenTask(param) {
  // 获取防控作业列表
  let url = `/api/BigData/taskScheduleData`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function antList(param) {
  // 获取蚁情普查数据列表
  let url = `/api/antAdmin/AntReported/list`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}
export function antData(param) {
  // 获取蚁情普查数据列表
  let url = `/api/antAdmin/AntData/get`;
  return http.post(url, param, modeUrlObj.baseURL).then(data => {
    return data
  })
}