import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    isRefresh: false,
    requests: [],
  },
  mutations: {
    save_user_info(state, info) {
      this.state.userInfo = info;
    },
    save_is_refresh(state, info) {
      this.state.isRefresh = info;
    },
    save_requests(state, info) {
      this.state.requests = info;
    },
  },
  actions: {},
  modules: {},
});
